<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createTime"
      headerTitle="查看协议详情"
      :title="`${detailInfo.createUser || ''}提交的协议审批申请`"
      :handleTabsObj="handleTabsObj"
    >
      <div class="cut" @click="handleCutPage">
        <van-icon name="exchange" />
        <span>查看合同</span>
      </div>
    </detail-top>
    <div :class="[detailInfo.status === 1 && agreementAuditAuthority === 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="服务费率" :value="detailInfo.serviceRate ? `${detailInfo.serviceRate}%` : ''" />
          <my-cell title="签约合同价" :value="`${detailInfo.settlementValue}${detailInfo.settlement}`" />
          <my-cell title="开票税率" :value="handleTaxRate(detailInfo.contractAgreementInvoices)" />
          <my-cell title="开票对象地址" :value="detailInfo.address" />
          <my-cell title="开票对象电话" :value="detailInfo.phone" />
          <my-cell title="开票开户行" :value="detailInfo.bankName" />
          <my-cell title="开票开户行账户" :value="detailInfo.bankNo" /> 
          <my-cell title="协议附件" />
          <my-files-view :fileList="detailInfo.contractAgreementFiles" />
          <my-cell title="备注" :value="detailInfo.remark" />
          <img v-if="detailInfo.status" class="status-img" :src="handleStatus(detailInfo.status)" />
        </div>
      </my-card>

      <!-- 标准合同module是10，非标准是11 -->
      <approval-process name="2" v-if="detailInfo.contractAgreementId" 
        :billId="detailInfo.contractAgreementId" :module="standard === 1 ? 10 : 11" />
    </div>
    <detail-approval-btn v-if="detailInfo.status === 1 && agreementAuditAuthority === 1" :billNo="detailInfo.agreementBillNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { contractView } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      reimId: '', // id
      tabsList: [],
      detailInfo: {},
      auditAuthority: 0,
      agreementAuditAuthority: 0,
      standard: 0,
      detail: {},
      agreementList: [],
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.reimId = this.$route.query.reimId;
    this.status = this.$route.query.status;
    this.totalAmount = this.$route.query.totalAmount;
    this.auditAuthority = this.$route.query.auditAuthority;
    this.agreementAuditAuthority = this.$route.query.agreementAuditAuthority;
    if (this.reimId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    getDetailInfo() {
      contractView({contractId: this.reimId}).then(res => {
        let obj = res.resultData;
        this.standard = obj.standard;
        this.detail = obj;
        this.detailInfo = obj.contractAgreementVOs[0];
        this.agreementList = obj.contractAgreementVOs
        let tempTabsList = []
        obj.contractAgreementVOs?.map((item, index) => {
          tempTabsList.push({
            key: index+1,
            title: `协议信息${index+1}`,
            info: item
          })
        })
        this.tabsList = tempTabsList
      })
    },
    handleTabsObj(e) {
      this.detailInfo = this.tabsList[e-1]?.info
    },
    handleCutPage(){
      this.$router.replace({
        name: 'contractView',
        query: {
          reimId: this.reimId,
          status: this.status,
          auditAuthority: this.auditAuthority,
          agreementAuditAuthority: this.agreementAuditAuthority,
        }
      })
    },
    handleStatus(status){
      if(status === 5){ // 驳回
        return rejectImg;
      } else if(status === 2){ // 通过
        return passImg;
      }
    },
    handleTaxRate(list){
      let name = '';
      list?.map(item => {
        name += `${item.invTaxName}${item.taxRate}%，`
      })
      return name;
    }
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .detail-item{
        background-color: #f7f8fcc9;
        border-radius: 6px;
        padding: 4px 10px;
        margin: 10px 0;
        @mixin main{
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          .detail-cell{
            flex: 1;
            display: flex;
            flex-direction: column;
            &:nth-child(1){
              text-align: left;
            }
            .title{
              font-size: 12px;
              color: $gray;
            }
            .value{
              margin: 4px 0;
              font-size: 14px;
              font-weight: 600;
              color: $font;
            }
          }
        }
        >.main-two{
          @include main;
          .detail-cell{
            &:nth-child(2){
              text-align: right;
            }
          }
        }
      }
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
    >.my-card{
      >.contract-list{
        margin: 10px 0;
        text-align: right;
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>